import React, { useState, useContext } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { BsCircleHalf, BsSearch } from "react-icons/bs";
import SettingsContext from "src/context/SettingsContext";
import { AuthContext } from "src/context/Auth";
import { useWeb3React } from "@web3-react/core";
import Logo from "src/component/Logo";
import {
  AppBar,
  Toolbar,
  makeStyles,
  IconButton,
  Hidden,
  SvgIcon,
  Typography,
  Box,
  Avatar,
  Button,
  Dialog,
} from "@material-ui/core";
import { Menu as MenuIcon } from "react-feather";
import ConnectWallet from "src/component/ConnectWallet";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    height: 70,
    padding: "0px 25px 0px 0px",
    // padding: "0 10px", //"0 10px"
    backgroundColor: " #000", //topbar bg color
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  avatar: {
    cursor: "pointer",
    width: 40,
    height: 40,
    "@media (max-width: 767px)": {
      width: "30px",
      height: "30px",
    },
  },
  walletbox: {
    padding: "10px",
    "& h5": {
      fontSize: "14px",
    },
  },
  desktopDrawer: {
    position: "absolute",
    right: 0,
    top: 30,
    width: 280,
    backgroundColor: "#272731",
  },
  userDetails: {
    "& h5": {
      color: theme.palette.primary.main,
      fontSize: "13px !important",
    },
    "& body2": {
      fontSize: "11px !important",
      lineHeight: "17px",
    },
  },
  logoicon: {
    paddingLeft: "25px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0px",
    },
  },

  contentTitle: {
    "& .MuiPaper": {
      root: {
        background: "rgba(0, 0, 0, 0.3) !important",
        // backdropFilter: "blur(125px) !important",
        borderRadius: "50px !important",
      },
    },
    "& .MuiDialogTitle": {
      root: {
        padding: "0px 15px 0px 0px !important",
      },
    },
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar
      elevation={0}
      className={clsx(classes.root, className)}
      color="inherit"
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            color="#00e0b0"
            onClick={onMobileNavOpen}
            style={{ marginRight: 10 }}
          >
            <SvgIcon fontSize="small">
              <MenuIcon style={{ color: "white" }} />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden xsUp>
          <Box padding={0} className={classes.logoicon}>
            <Logo width={150} />
          </Box>
        </Hidden>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData() {
  const classes = useStyles();
  const history = useHistory();
  const { account } = useWeb3React();
  const themeSeeting = useContext(SettingsContext);
  const auth = useContext(AuthContext);
  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    });
  };

  return (
    <>
      <Box flexGrow={1} />
      <Box className={classes.userDetails}>
        {/* CONNECT MODAL*/}
        <Dialog
          open={auth?.isMetaMaskOpen}
          onClose={() => {
            auth.setIsOpenMetaMaskOpen(false);
          }}
          maxWidth="lg"
          className={classes.contentTitle}
        >
          <ConnectWallet
            onClose={() => {
              auth.setIsOpenMetaMaskOpen(false);
            }}
            account={account}
          />
        </Dialog>

        <Typography variant="h5">
          {`${auth?.userData?.firstName}` + " " + `${auth?.userData?.lastName}`}
        </Typography>
        <Typography
          variant="body2"
          style={{
            fontSize: "12px",
            lineHeight: "20px",
            color: "#adadad", //#57617B
          }}
        >{`${auth?.userData?.email}`}</Typography>
      </Box>
      &nbsp; &nbsp;&nbsp;
      <Box>
        <Avatar
          src={
            auth?.userData?.profilePic ? `${auth?.userData?.profilePic}` : " "
          }
          className={classes.avatar}
          onClick={() => history.push("/profile")}
        />
      </Box>
    </>
  );
}
