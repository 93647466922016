import {
  Box,
  Typography,
  Grid,
  Hidden,
  IconButton,
  withStyles,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useContext, useState, useEffect } from "react";
import { SUPPORTED_WALLETS } from "src/connectors";
import AnimateLogo from "src/component/AnimateLogo";
import MuiDialogContent from "@material-ui/core/DialogTitle";
import MuiDialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import SettingsContext from "src/context/SettingsContext";
import { UserContext } from "src/context/User";
import { AuthContext } from "src/context/Auth";
const useStyles = makeStyles((theme) => ({
  imgbox: {
    "& img": {
      // maxHeight: "100%",
      maxWidth: "100%",
      height: "auto",
      width: "auto",
      display: "block",
      borderRadius: "10px",
    },
  },
  grid: {
    // padding: "10px",
    overflowY: "hidden !important",
    overflowX: "hidden !important",
  },
  logintext: {
    "& h5": {
      fontSize: "20px",
      lineHeight: "32px",
      textAlign: "left",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
  },
  metamaskhead: {
    position: "relative",
    zIndex: "9",
    border: "1px solid #ffffff0f",
    background: theme.palette.background.connectWallet,
    borderRadius: "10px",
    padding: "16px",
    marginTop: "15px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
    },
    "& h5": {
      fontStyle: "normal",
      fontSize: "16px",
      lineHeight: "20px",
      textTransform: "capitalize",
      // color: "#fff",
    },
  },
  walletBox: {
    position: "relative",
    zIndex: "9",
    marginBottom: "18px",
    "& .closeIcon": {
      top: "-23px",
      right: "-19px",
      border: "none",
      cursor: "pointer",
      position: "absolute",
      background: "transparent",
      [theme.breakpoints.down("sm")]: {
        top: "-23px",
        right: "-20px",
      },
    },
    "& .conshade1": {
      top: "0px",
      left: "0%",
      width: "342px",
      filter: "blur(96px)",
      height: "341px",
      zIndex: "1",
      position: "absolute",
      // background: "#9C162E",
      borderRadius: "1000px",
    },
    "& .conshade2": {
      top: "53px",
      right: "6%",
      width: "200px",
      filter: "blur(96px)",
      height: "200px",
      borderRadius: "1000px",
      zIndex: "1",
      position: "absolute",
      // background: "#6734aa",
    },
  },
  connectWalletModal: {
    display: "flex",
    alignItems: "center",
    "& .bottomText": {
      color: theme.palette.text.dull,
      fontSize: "16px",
      marginLeft: "4px",
      cursor: "pointer",
    },
  },
  MuiDialogContent: {
    "& .MuiDialogTitle-root": {
      overflow: "hidden",
    },
  },
}));

const DialogContent = withStyles((theme) => ({
  root: {},
}))(MuiDialogContent);
const Dialog = withStyles((theme) => ({}))(MuiDialog);

const ConnectWallet = ({ onClose, account }) => {
  const classes = useStyles();
  const themeSeeting = useContext(SettingsContext);
  const user = useContext(AuthContext);
  return (
    <Box className="newModalBorder">
      <Box
        className={
          themeSeeting?.settings?.theme === "DARK" ? "mainbox" : "mainbox1"
        }
      >
        <div
          className={
            themeSeeting?.settings?.theme === "DARK"
              ? "logindarkleftModal"
              : "loginlightModal"
          }
        ></div>
        <div
          className={
            themeSeeting?.settings?.theme === "DARK"
              ? "loginDarktModal"
              : "loginlightrightModal"
          }
        ></div>
        <Box style={{ position: "relative", zIndex: "1", padding: "25px" }}>
          <Box className={classes.walletBox}>
            <IconButton className="closeIcon" onClick={onClose}>
              <CloseIcon style={{ fontSize: "20px" }} />
            </IconButton>

            {/* <Box className="conshade1"></Box>
            <Box className="conshade2"></Box> */}
          </Box>

          <Grid
            container
            spacing={3}
            className={classes.grid}
            alignItems="center"
          >
            <Hidden xsDown>
              <Grid item lg={5} md={5} sm={5} xs={12} align="left">
                <Box
                  align="left"
                  className={classes.imgbox}
                  style={{ position: "relative", zIndex: "9" }}
                >
                  <AnimateLogo />
                </Box>
              </Grid>
              <Grid item lg={1} md={1} sm={1} xs={12} align="left"></Grid>
            </Hidden>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Box style={{ maxWidth: "235px" }}>
                <Box
                  className={classes.logintext}
                  textAlign="center"
                  style={{ positin: "relative", zIndex: "1" }}
                >
                  <Typography variant="h5" color="primary">
                    {account
                      ? "Are you sure want to disconnect your wallet?"
                      : "Connect your wallet."}
                  </Typography>
                </Box>
                {!account && (
                  <Grid container>
                    {SUPPORTED_WALLETS.map((item, i) => {
                      return (
                        <Grid item lg={12} xs={12}>
                          <Box
                            align="center"
                            key={i}
                            className={classes.metamaskhead}
                            onClick={() => {
                              if (account) {
                                user.disconnectWallet();
                                localStorage.removeItem("walletName");
                              } else {
                                user.connectWallet(item.data);
                              }
                            }}
                            // style={
                            //   connectedWallet !== item.name
                            //     ? { background: '#000' }
                            //     : {}
                            // }
                          >
                            <Box>
                              <img
                                src={"/images/metamask_1.png"}
                                alt=""
                                style={{ width: "55px" }}
                              />
                            </Box>
                            <Box mt={1}>
                              <Typography variant="h5">
                                {account ? "Disconnect" : "Connect Wallet"}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
                {account && (
                  <Box
                    align="center"
                    className={classes.metamaskhead}
                    onClick={() => {
                      user.disconnectWallet();
                      localStorage.removeItem("walletName");
                    }}
                  >
                    <Box mt={1}>
                      <Typography variant="h5">Disconnect</Typography>
                    </Box>
                  </Box>
                )}
              </Box>
              {/* <Box mt={2} className={classes.connectWalletModal}>
            <img src="images/learmore.png" alt="" /> &nbsp;
            <Typography variant="h5" className="bottomText">
              {" "}
              Learn how to connect
            </Typography>
          </Box> */}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default ConnectWallet;
