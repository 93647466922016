export const baseurl = "https://node-metaverse.mobiloitte.io/"; //staging server

// export const baseurl = "http://172.16.6.118:1955/";

let base = `${baseurl}api/v1`;
let user = `${base}/user`;
let admin = `${base}/admin`;
let stack = `${base}/stack`;
let kyc = `${base}/kyc`;
let static1 = `${base}/static`;
let enroll = `${base}/enroll`;
let order = `${base}/order`;
let building = `${base}/building`;

let staticLink = `${base}/staticLink`;
let contact1 = `${base}/contactUs`;
let category = `${base}/category`;
let bid = `${base}/bid`;
let course = `${base}/course`; //course/listCourseParticular

const Apiconfigs = {
  login: `${admin}/login`,
  register: `${admin}/register`,
  verifyOTP: `${admin}/verifyOTP`,
  resendOTP: `${admin}/resendOTP`,
  forgotPassword: `${admin}/forgotPassword`,
  adminProfile: `${admin}/adminProfile`,
  updateAdminProfile: `${admin}/updateAdminProfile`,
  resetPassword: `${admin}/resetPassword`,
  changePassword: `${admin}/changePassword`,
  uploadImage: `${user}/uploadImage`,
  uploadMultipleImage: `${user}/uploadMultipleImage`,
  packageList: `${admin}/packageList`,
  packageView: `${admin}/packageView`,
  editPackage: `${admin}/editPackage`,
  listOfActiveAndCompletedCourse: `${admin}/listOfActiveAndCompletedCourse`,
  listEnrolldUserUsingCourseID: `${admin}/listEnrolldUserUsingCourseID`,
  commissionList: `${admin}/commissionList`,

  //Course Section
  listCourseParticular: `${course}/listCourseParticular`,
  course: `${course}/course`,
  addCourseCategory: `${course}/addCourseCategory`,
  courseCategoryList: `${course}/courseCategoryList`,
  deleteCourseCategory: `${course}/deleteCourseCategory`,
  editCourseCategory: `${course}/editCourseCategory`,

  //Enroll
  listEnrolldUser: `${enroll}/listEnrolldUser`,

  // admin
  dashboard: `${admin}/dashboard`,
  listUser: `${admin}/listUser`,
  deleteUser: `${admin}/deleteUser`,
  setUserType: `${admin}/setUserType`,
  viewUser: `${admin}/viewUser`,
  allPlaceOrderList: `${admin}/allPlaceOrderList`,
  blockUnblockUser: `${admin}/blockUnblockUser`,
  blockUnblockStudent: `${admin}/blockUnblockStudent`,
  removeUserType: `${admin}/removeUserType`,
  uploadedCoursesList: `${admin}/uploadedCoursesList`,
  studentSubCourse: `${admin}/studentSubCourse`,
  detailsOfPlotIstituteCourses: `${admin}/detailsOfPlotIstituteCourses`,
  blockUnblockCourses: `${admin}/blockUnblockCourses`,
  blockUnblockInstitute: `${admin}/blockUnblockInstitute`,
  deleteInstitute: `${admin}/deleteInstitute`,
  listAllRedeemRequest: `${admin}/listAllRedeemRequest`,
  acceptReedeemRequest: `${admin}/acceptReedeemRequest/`,
  rejectReedeemRequest: `${admin}/rejectReedeemRequest`,
  listOfActiveCourses: `${admin}/listOfActiveCourses`,
  listNftHistory: `${admin}/listNftHistory`,
  placeOrderList: `${order}/placeOrderList`,
  viewPlaceOrder: `${order}/viewPlaceOrder`,
  listOfInstitute: `${admin}/listOfInstitute/{userId}`,
  updateUsdtPrice: `${user}/updateUsdtPrice`,
  getUsdtPrice: `${user}/getUsdtPrice`,

  //Kyc
  listAllKycRequest: `${kyc}/listAllKycRequest`,
  viewKycDetail: `${kyc}/viewKycDetail`,
  acceptKycRequest: `${kyc}/acceptKycRequest`,
  rejectKycRequest: `${kyc}/rejectKycRequest`,
  viewKycOfParticularUser: `${kyc}/viewKycOfParticularUser`,

  //blogs
  addBlogs: `${admin}/addBlogs`,
  listBlogs: `${admin}/listBlogs`,
  viewBlogs: `${admin}/viewBlogs`,
  editBlogs: `${admin}/editBlogs`,
  deleteBlogs: `${admin}/deleteBlogs`,
  commissionsAmount: `${admin}/commissionsAmount`,

  //Stacking
  stackingList: `${stack}/stackingList`,
  stackingListForUser: `${stack}/stackingListForUser`,
  // enableFeed: `${feed}/enableFeed`,
  // disableFeed: `${feed}/disableFeed`,

  staticContenttype: `${static1}/static/`,
  updateCommission: `${static1}/updateCommission`,
  getCommission: `${static1}/getCommission`,
  bidListByOrder: `${bid}/bidListByOrder`,
  //
  //building
  listInstitute: `${building}/listInstitute`,
  viewInstitute: `${building}/viewInstitute`,
  //Social
  staticLinkContentList: `${staticLink}/staticLink`,
  staticLinkContentId: `${staticLink}/staticLink/`,

  // ContactUs
  listContactUs: `${contact1}/contactUs`,
  category: `${category}/category`,
  editCategory: `${category}/editCategory`,
};
export default Apiconfigs;
