import React, { useEffect, useContext, useState } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { Drawer, Hidden, makeStyles } from "@material-ui/core";
import NewApp from "./NewApp";

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 220,
    backgroundColor: "#030208",
    // backgroundColor: theme.palette.background.default,
  },
  desktopDrawer: {
    width: 220,
    top: 70,
    height: "100%",
    backgroundColor: "#030208",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  ButtonBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "10px",
  },
  logoicon: {
    display: "flex",
    marginTop: "16px",
    alignItems: "center",
    marginLeft: "30px",
  },
  logoutbutton: {
    // justifyContent: "space-between",
    paddingLeft: 10,
    borderRadius: 10,
    width: "100px",
    textAlign: "center",
    color: "#7d68bf",
    border: "1px solid #7d68bf",
    textAlign: "center",
    fontWeight: "bold",
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <NewApp />
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <NewApp />
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
